import React from "react"
import styled, { css } from "styled-components"
import { media } from "../../../styles/media"
const StyledHeadline = styled.div`
  width: 100%;
  .os1 {
    padding-bottom: 30px;
    line-height: 20px;
  }
  .os2 {
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    padding-inline-start: 4rem;
    .os2a {
      color: #663100;
      font-weight: bold;
      padding-right: 20px;
      font-size: 1.32em;
    }
    .os2b {
      border-left: 1px solid #663100;
      padding-left: 20px;
      p {
        color: #663100;
        font-size: 0.85em;
      }
    }
    ${() =>
      media.sp(css`
        padding-inline-start: 0;
      `)}
  }
`
export default function Headline({ content }) {
  return <StyledHeadline dangerouslySetInnerHTML={{ __html: content }} />
}
