import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"
import styled, { css } from "styled-components"
import { media } from "../styles/media"

const Tesla = styled.div`
  width: 30%;
  margin: 20px 1.5%;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  position: relative;
  a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
  .model3 {
    width: 45%;
    .gatsby-image-wrapper {
      width: 100%;
    }
  }
  .modelY {
    width: 52%;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    .gatsby-image-wrapper {
      width: 25%;
    }
    h5 {
      font-weight: bold;
      font-size: 0.9em;
    }
    p {
      font-size: 0.8em;
      line-height: 1.05em;
      padding: 10px 0;
      margin: 0 auto;
    }
  }
  ${() =>
    media.tablet(css`
      width: 46%;
      margin: 20px 2%;
    `)}
  ${() =>
    media.sp(css`
      width: 100%;
      margin: 5px auto;
    `)}
`

const SmallImageWithLogo = ({
  fluid,
  brand,
  text,
  name,
  brandSlug,
  productSlug,
}) => {
  return (
    <Tesla>
      <div className="model3">{fluid && <Image fluid={fluid} />}</div>
      <div className="modelY">
        {brand && <Image fluid={brand} />}
        <p>{text}</p>
        <h5>{name}</h5>
      </div>
      <Link to={"/" + brandSlug + "/" + productSlug + "/"}></Link>
    </Tesla>
  )
}

export default SmallImageWithLogo
