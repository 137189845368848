import React from "react"
import styled from "styled-components"
import SmallImageWithLogo from "../../../components/SmallImageWithLogo"
import { truncateCatchPhrase } from "../../../utils/helper"

const ItemsSection = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  padding: 50px 0 0px;
  .resultArticle {
    margin-bottom: 50px;
    width: 30%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    .resultImage {
      width: 40%;
      .gatsby-image-wrapper {
        width: 100%;
      }
    }
    .resultTxt {
      width: 60%;
      p {
        font-size: 0.7em;
        margin-bottom: 0.62em;
      }
      h3 {
        font-weight: bold;
        line-height: 15px;
      }
    }
  }
`

export default function Items({ products, slug }) {
  return (
    <ItemsSection>
      {products.map(product => (
        <SmallImageWithLogo
          fluid={product.mainImage.childImageSharp.fluid}
          text={product.catchPhrase && truncateCatchPhrase(product.catchPhrase)}
          name={product.name}
          brandSlug={slug}
          productSlug={product.slug}
          key={product.id}
        />
      ))}
    </ItemsSection>
  )
}
