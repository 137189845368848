import React from "react"
import ContentContainer from "./ContentContainer"
import Headline from "./Headline"
import Items from "./Items"
import HeaderVisual from "../../../components/HeaderVisual"
import ProductLinks from "../../../components/ProductLinks"

export default function PageContent({
  pageData,
  products,
  slug,
  backgroundImageSrcset = "",
  mainLogoSrcset = "",
}) {
  return (
    <>
      <HeaderVisual
        backImage={
          backgroundImageSrcset || pageData.pageMainVisual.childImageSharp.fluid
        }
        mainLogo={mainLogoSrcset || pageData.mainLogo.childImageSharp.fluid}
      />
      <ContentContainer>
        <Headline content={pageData.underContents} />
        <Items products={products} slug={slug} />
        <ProductLinks />
      </ContentContainer>
    </>
  )
}
