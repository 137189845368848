import React from "react"
import { css } from "styled-components"
import styled from "styled-components"

const StyledContentContainer = styled.div(
  ({ theme }) => css`
    padding: 50px 5% 100px;
    max-width: 1300px;
    margin: 0 auto;
    h3 {
      font-size: 1.32em;
      line-height: 1.62em;
      color: ${theme.colors.primary.brown};
    }
  `
)

export default function ContentContainer({ children }) {
  return <StyledContentContainer>{children}</StyledContentContainer>
}
